import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundComponent from '../../core/components/notFound';
import HomeContainer from '../containers/home';

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (
    <Routes>
      <Route path="/" element={<HomeContainer />} />
      <Route element={<NotFoundComponent />} />
    </Routes>
  );
}
