import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import ImageCarousel from './utils/carousel';

function ActivityItem(props) {
  const { activity, index} = props;

  const { image, name } = activity;
  return (
    <div className={`activity ${true ? "start" : "end"} item-${index+1}`} key={`act-item-${index+1}`}>
      <div className='icon left' style={{ backgroundImage: `url('${image}')`}} />
      <span>{name}</span>
    </div>
  );
}

function ServicesComponent(props) {
  const { services } = props;

  return (
    <section className="services" id="services">
      <div className="container">
        <div className="title">
          <div className='line' />
          <h2>{services.title}</h2>
          <h3>{services.subtitle}</h3>
        </div>
        <div className='content'>
            <Fade left>
              <div className='wrapper'>
                  <ImageCarousel numImages={services.numImages} />
              </div>
            </Fade>
            <div className='activities'>
                {services.activities.map((activity, index) => {
                    return (
                      <Fade right key={`act-item-${index+1}`} delay={(index+1)*100}>
                        <ActivityItem activity={activity} index={index} />
                      </Fade>
                    )
                })}
                <Fade right delay={6*100}>
                  <div className='activity special'>
                    <img src='/images/icons/services/spaceship.png' alt='spaceship' />
                    <span>{services.special[0]}</span>
                    {services.special.length > 1 && <span>{services.special[1]}</span>}
                  </div>
                </Fade>
            </div>
        </div>
      </div>
      {/* <div className='wave'/> */}
    </section>
  );
}

ServicesComponent.propTypes = {
    services: PropTypes.object.isRequired,
};

export default ServicesComponent;
