import React from 'react';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';
import { Link as LinkR } from 'react-router-dom';

const renderLinks = (links) => {
  return Object.keys(links).map((link, index) => {
    return (
      <li className="nav-item" key={link}>
        <Link
          to={link}
          className="nav-link"
          activeClass="active"
          smooth="easeInOutCubic"
          offset={10}
          delay={0}
          duration={500}
        >
          {links[link]}
        </Link>
      </li>
    );
  });
};

function NavbarComponent(props) {
  
  const { links, location } = props;

  const selected = location.pathname.split('/').pop();

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <LinkR to="/" className="logo-link">
          <img
            className="logo"
            src="images/logo/logo.png"
            alt="smt"
          />
        </LinkR>
        <div className="navbar-collapse" id="navbarColor03">
          <ul className="collapse navbar-nav mr-auto">{renderLinks(links)}</ul>
          <ul className="navbar-nav navbar-right">
            <li
              className="nav-item active"
              style={{ display: 'flex', alignItems: 'center', marginRight: 0 }}
            >
              {selected === 'sk' ? (
                <a href="/" className="lang">
                  <img src="/images/icons/uk.png" alt="english"/>
                  <span>EN</span>
                </a>
              ) : (
                <a href="/sk" className="lang">
                  <img src="/images/icons/sk.png" alt="slovak"/>
                  <span>SK</span>
                </a>
              )}
            </li>
          </ul>
        </div>
        
      </div>
    </nav>
  );
}

NavbarComponent.propTypes = {
  location: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default NavbarComponent;
