/* eslint-disable react/no-unused-state */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { SiGooglechat } from "react-icons/si";
import toastr from 'toastr';
import Button from "@material-ui/core/Button";
import emailjs from '@emailjs/browser';

toastr.options.positionClass = 'toast-bottom-right';
toastr.options.closeButton = true;


function InfoContainer(props) {
  const { title, subtitle, icon} = props;

  return (
    <div className="info-wrapper">
      <div
        className="icon"
        style={{
          backgroundImage: `url('/images/icons/${icon}.png')`,
        }}
      />
      <span className="type">{title}</span>
      <span className="data">{subtitle}</span>
    </div>
  );
}

function ContactComponent(props) {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("Submit");
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');

  const { contact } = props;

  const mapStateSetters = {
    email: { setter: (value) => setEmail(value), state: email },
    status: { setter: (value) => setStatus(value), state: status },
    message: { setter: (value) => setMessage(value), state: message },
    text: { setter: (value) => setMessage(value), state: message },
    name: { setter: (value) => setName(value), state: name },
    meno: { setter: (value) => setName(value), state: name },
  };

  const handleChange = (field, event) => {
    const { value } = event.target;
    mapStateSetters[field].setter(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setStatus("Sending...");
    emailjs.send('service_hpeazep', 'template_ydi01x7', { from_name: name, reply_to: email, message }, 'eno_AT_EOcY6-f7dF')
      .then((result) => {
        setEmail("");
        setMessage("");
        setName("");
        setStatus("Success");
        toastr.success(contact.form.success.subtitle, contact.form.success.title);
      }, (error) => {
          console.log(error.text);
      });
  }

  const { sections } = contact;
  const formFields = contact.form.fields;
  const contactInfo = contact.info;
  return (
    <section className="contact" id="Contact">
      <div className="container">
        <div className="info">
          <div className="text">
            <h5>{contact.title}</h5>
            <h6>{contact.subtitle}</h6>
          </div>
          <div className="boxes">
            {Object.keys(sections).map((sect, index) => {
              return (
                <InfoContainer
                  icon={sect}
                  title={sections[sect]}
                  subtitle={contactInfo[index]}
                  key={`contact-section-${index + 1}`}
                />
              );
            })}
          </div>
        </div>
        <div className="divider" />
        <div className="form">
          <div className="title">
            <SiGooglechat />
            <span>{contact.form.title[0]}</span>
            <span className="bold">{contact.form.title[1]}</span>
          </div>
          <form className="contact-form" noValidate autoComplete="off" onSubmit={onSubmit}>
            {Object.keys(formFields).map((field, index) => {
              const fieldName = field.toLowerCase();
              return (
                <div className="form-group" key={`form-field-${index+1}`}>
                  <label htmlFor={fieldName.toLowerCase()}>{formFields[fieldName]}</label>
                  {fieldName === "message" ? (
                    <textarea
                      name="message"
                      className="form-control"
                      value={mapStateSetters[fieldName].state}
                      onChange={(e) => handleChange(fieldName, e)}
                    />
                  ): (
                    <input
                      name={fieldName}
                      type="text"
                      className="form-control"
                      value={mapStateSetters[fieldName].state}
                      onChange={(e) => handleChange(fieldName, e)}
                    />
                  )}
                </div>
              )
            })}
            <Button variant="contained" type="submit" color="primary" className="submit">
              {contact.form.button}
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
}

ContactComponent.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default ContactComponent;
