import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import FooterComponent from '../components/footer';
import NavbarComponent from '../components/navbar';
import contentEN from '../../../data/content_EN.json';
import contentSK from '../../../data/content_SK.json';

const CoreLayout = (props) => {
  const { children } = props;
  const location = useLocation();

  location.pathname = location.pathname.replace(/\/$/, '');
  const subpath = location.pathname.split('/').pop();
  return (
    <div className="page">
        <NavbarComponent 
          links={subpath === 'sk' ? contentSK.navbar : contentEN.navbar}
          location={location}
        />
      {children}
      {<FooterComponent taxTitle={subpath === 'sk' ? contentSK.footer.tax : contentEN.footer.tax} />}
    </div>
  );
};

CoreLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default CoreLayout;
