import React from 'react';
import { useLocation } from 'react-router-dom';
import HomeComponent from '../components/home';
import contentEN from '../../../data/content_EN.json';
import contentSK from '../../../data/content_SK.json';

const HomeContainer = () => {
  const location = useLocation();
  const lang = location.pathname.split('/').pop();

  return <HomeComponent content={lang === 'sk' ? contentSK : contentEN} />;
};

export default HomeContainer;
