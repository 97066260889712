import React from 'react';
import { Route, Routes as RRoutes, } from 'react-router-dom';
import NotFoundComponent from './client/core/components/notFound';
import CoreLayout from './client/core/layouts/index';
import homeRoute from './client/home/routes/index';

const Routes = () => (
  <CoreLayout>
    <RRoutes>
      <Route path="/" element={homeRoute()} />
      <Route path=":lan/*" element={homeRoute()} />
      <Route element={<NotFoundComponent />} />
    </RRoutes>
  </CoreLayout>
);

export default Routes;
