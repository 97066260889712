import React from "react";
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.min.css';
import "@fontsource/nunito-sans";
import './styles/client.css';
import './styles/template.css';


const container = document.getElementById('app');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Routes />
    </BrowserRouter>
);