import React from 'react';
import { RiLinkedinLine } from 'react-icons/ri';

const FooterComponent = ({ taxTitle }) => (
  <footer>
    <div className="container">
      <div className="footer-wrapper">
        <div className="identity">
          <img src="/images/logo/logo.png" alt="smt" />
          <div>
            <p>
              <span>&copy;&nbsp;2022&nbsp;</span>
              SMT elektro
            </p>
            <p style={{fontSize: "10px"}}>
              {`${taxTitle}: 54776830`}
            </p>
          </div>
        </div>
        <div className="rrss">
          <div className="mail">
            <a href="mailto:smt@smtelektro.com">smt@smtelektro.com</a>
          </div>
          <div className="social">
            <a
              href="https://www.linkedin.com/company/smt-elektro/"
              target="_blank"
              className="in"
              rel="noreferrer"
            >
              <div>
                <RiLinkedinLine />
              </div>
            </a>
            {/* <a
              href="https://www.instagram.com/smtelektro.com/"
              target="_blank"
              className="ig"
              rel="noreferrer"
            >
              <div>
                <RiInstagramLine />
              </div>
            </a>
            <a
              href="https://twitter.com/smtelektro.com"
              target="_blank"
              className="tw"
              rel="noreferrer"
            >
              <div>
                <RiTwitterFill />
              </div>
            </a>
            <a
              href="https://www.facebook.com/smtelektro.com"
              target="_blank"
              className="fb"
              rel="noreferrer"
            >
              <div>
                <RiFacebookFill />
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default FooterComponent;
