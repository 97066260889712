import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

function AboutComponent(props) {
  const { about } = props;

  return (
    <section className="about" id="about">
      <div className="container">
        <div className="title">
          <div className='line' />
          <h2>{about.title}</h2>
          {/* <h3>{about.subtitle}</h3> */}
        </div>
        <div className='sections'>
        {about.sections.map((section, index) => {
          return (
            <Fade bottom delay={index*250} key={`section-${index+1}`}>
              <div className={`info-box sect-${index}`}>
                <div className='text'>
                  <h4>{section.title}</h4>
                  <p>{section.paragraph}</p>
                </div>
                <div className="image" style={{backgroundImage: `url(${section.image})`}} />
              </div>
            </Fade>
          )
        })}
        </div>
      </div>
      <div className='ellipse' />
    </section>
  );
}

AboutComponent.propTypes = {
  about: PropTypes.object.isRequired,
};

export default AboutComponent;
