import React from 'react';
import PropTypes from 'prop-types';
import HeroComponent from './hero';
import AboutComponent from './about';
import ContactComponent from './contact';
import ServicesComponent from './services';

function HomeComponent(props) {

  const { content } = props;

  return (
    <main id="home">
      <HeroComponent hero={content.hero} />
      <AboutComponent about={content.about} />
      <ServicesComponent services={content.services} />
      <ContactComponent contact={content.contact} />
    </main>
  );
}

HomeComponent.propTypes = {
  content: PropTypes.object.isRequired,
};

export default HomeComponent;
