import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function ImageCarousel(props) {

    const { numImages } = props;
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1300, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      className="image-carousel"
      infinite
      swipeable
      draggable
      autoPlay
      autoPlaySpeed={3000}
    >
      {[...Array(numImages).keys()].map((index) => {
        return (
          <CarouselItem
            image={`/images/services/${index+1}.jpg`}
            key={`image-carousel-${index+1}`}
          />
        );
      })}
    </Carousel>
  );
}

function CarouselItem(props) {

  const { image } = props;

  return (
    <div
      className="item"
    >
      <div
        className="image"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
    </div>
  );
}

CarouselItem.propTypes = {
  image: PropTypes.string.isRequired,
};

ImageCarousel.propTypes = {
  numImages: PropTypes.number.isRequired,
};
