import React from "react";
import PropTypes from "prop-types";

function HeroComponent(props) {
  const { hero } = props;
  return (
    <section className="hero" id="hero">
      <div className="container">
        <div className="hero-wrapper">
          <div className="triangle"/>
          <div className="box-title">
            <h1 className="msg-h1 title">{hero.title}</h1>
            {/* <p className="subtitle">{hero.subtitle}</p> */}
          </div>
          <div className="hero-image">
            {/* <ImageCarousel /> */}
            <img
              className="panel-pic"
              src="/images/hero-image-v2.png"
              alt="panel"
              style={{ width: "95%" }}
            />
          </div>
        </div>
      </div>
      <div className="waves" />
      {/* <div class="wave-shape">
        <svg data-name="layer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" class="shape-fill"></path>
        </svg>
      </div> */}
    </section>
  );
}

HeroComponent.propTypes = {
  hero: PropTypes.object.isRequired,
};

export default HeroComponent;
